<template>
  <div class="relative">
    <span class="secondhead-text">Goals and Deadlines</span>
  </div>
  <loading-spinner v-if="is_fetching_milestones" />
  <div v-else>
    <div>
      Deadlines are required as part of Mentor Externship. Goals are not
      required, but strongly encouraged to help you to stay on track to meet the
      Deadlines.
      <span class="font-semibold">
        Click on a Deadline or Goal to see the steps needed to complete it.
      </span>
    </div>
    <div class="pt-4 pb-7 text-purple" data-cy="content-milestones">
      <div class="">
        <Carousel
          :items-to-show="3.232"
          :wrap-around="false"
          class="px-1"
          v-model="milestone_scroll_index"
        >
          <Slide
            v-for="milestone in student_milestones"
            :key="milestone.id"
            class="p-0"
          >
            <CarouselCard
              :milestone="milestone"
              @cardClicked="showMilestone(milestone.id)"
            />
          </Slide>
          <template #addons>
            <Navigation class="text-purple -mx-1.5"></Navigation>
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import CarouselCard from "@/components/displays/student-displays/milestones-plan/subcomps/CarouselCard";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import { mapActions, mapGetters, mapState } from "vuex";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";

export default {
  name: "Milestones",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    LoadingSpinner,
    CarouselCard,
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      toggle_carousel: true,
      milestone_scroll_index: 0,
    };
  },
  emits: ["showMilestone"],
  mounted() {
    this.milestone_scroll_index = this.milestone_index;
  },
  methods: {
    showMilestone(id) {
      this.$emit("showMilestone", { display_id: id });
    },
    ...mapActions(["fetch_milestone_dates"]),
  },
  computed: {
    ...mapGetters(["milestone_index", "is_fetching_milestones"]),
    ...mapState({
      student_milestones: (state) => state.milestones.student_milestones,
    }),
  },
};
</script>

<style scoped></style>
