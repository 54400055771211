export default {
  state: {
    progress_loading: false,
    student_id: "",
    term_id: "",
    last_approved_activity: "",
    total_approved_distinct_Experiences: 0,
    total_approved_experiences: 0,
    total_approved_hours: 0,
    orientation: 0,
    ppdp_meetings: 0,
    latest_ppdp_meeting_date: new Date(),
    debriefings: 0,
    networking: 0,
    year_end_meeting: 0,
    year_end_meeting_date: 0,
    points_of_contact: 0,
    mid_year_milestone_met: false,
    latest_experience_date_eligible_for_mid_year_milestone: new Date(),
    year_end_milestone_met: false,
    latest_experience_date_eligible_for_year_end_milestone: new Date(),
  },
  getters: {
    "is_progress_loading": (state) => state.progress_loading
  },
  mutations: {
    update_student_progress: (state, progress) => {
      console.log(progress)
      Object.entries(progress).forEach(([key, value]) => (state[key] = value));
    },
  },

  actions: {
    getStudentProgress: async ({ rootState, state, dispatch, commit }) => {
      console.log(`TERM:::${rootState.level_store.acad_level.term.term_id}`);
      state.progress_loading = true;
      return dispatch("fetchResource", {
        method: "GET",
        resource: "student/progress",
        params: {
          term: rootState.level_store.acad_level.term.term_id,
        },
      }).then((data) => {
        commit("update_student_progress", data[0]);
      }).finally(() => state.progress_loading = false);
    },
  },
};
