<template>
  <button
    :data-cy="milestone.cy_id"
    class="group text-left inline-block px-0.5 w-full"
    @click="setCardDisplay(milestone.id, !milestone.display)"
  >
    <div
      :class="[
        card_style.style,
        card_style.disabled ? '' : 'hover:text-white hover:bg-purple',
      ]"
      class="relative"
    >
      <div class="py-1 px-2.5 font-bold h-12">{{ milestone.description }}</div>
      <div class="flex justify-center">
        <div class="h-10 w-10/12 pb-1">
          <div
            :class="{
              'items-center rounded px-1': true,
              'bg-alert-danger text-white flex py-2':
                milestone.status === 'OVERDUE',
              'bg-alert-warn text-dark-gray flex py-2':
                milestone.status === 'UP_COMING',
              'py-2': milestone.status === 'TODO',
              'bg-alert-success text-white flex py-2':
                milestone.status === 'COMPLETE',
            }"
          >
            <font-awesome-icon
              v-if="
                ['OVERDUE', 'UP_COMING', 'COMPLETE'].includes(milestone.status)
              "
              :icon="
                milestone.status === 'UP_COMING'
                  ? 'clock'
                  : milestone.status === 'OVERDUE'
                    ? 'exclamation-triangle'
                    : 'check-circle'
              "
              class="text-2xl mx-0.5"
            />
            <div class="px-1 text-sm whitespace-wrap">
              <div v-if="milestone.status === 'OVERDUE'">Missed</div>
              <div v-else-if="milestone.status === 'UP_COMING'">Due Soon</div>
              <div v-else-if="milestone.status === 'COMPLETE'">Completed</div>
              <br v-else />
            </div>
          </div>
        </div>
      </div>
      <div
        :class="[
          'relative pt-1 pb-1.5 mx-2.5 border-t border-dark-purple group-hover:border-white text-sm',
          {
            'border-white': milestone.display,
          },
        ]"
      >
        <strong>Due: </strong>
        <span :data-cy="`${milestone.cy_id}_due_date`">
          {{ milestone_due_date }}
        </span>
      </div>
      <div
        style="background-color: white"
        v-if="milestone.display"
        class="absolute text-purple right-16"
      >
        &#9660;
      </div>
    </div>
  </button>
</template>

<script>
import { mapMutations } from "vuex";
import { FormatDate } from "../../../../../composables/date_utils";

export default {
  name: "CarouselCard",
  components: {},
  props: {
    milestone: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    setCardDisplay(id, display) {
      this.set_milestone_steps_display({ id: id, display: display });
      this.$emit("cardClicked");
    },
    ...mapMutations(["set_milestone_steps_display"]),
  },
  computed: {
    milestone_due_date: function () {
      if (this.milestone?.due_date) {
        return FormatDate(this.milestone.due_date);
      }
      return "";
    },
    card_style: function () {
      if (this.milestone.display) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return {
          style: `border border-purple bg-purple text-white rounded`,
          disabled: false,
        };
      } else {
        return {
          style: `border border-purple rounded`,
          disabled: false,
        };
      }
    },
  },
};
</script>

<style scoped></style>
