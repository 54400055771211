/*eslint-disable*/
const state = {
  acad_levels: [],
  prefix_options: ["Mr.", "Ms.", "Mrs.", "Dr.", "The Honorable"],
  suffix_options: ["Jr.", "Sr.", "Esq."],
  student_statuses: ["ACTIVE", "INACTIVE"],
  mentor_statuses: [
    "Active",
    "Active Paused",
    "Call In Future",
    "Follow Up",
    "Prospective",
    "Recruited",
    "Declined",
    "Did Not Respond",
    "Director Retired",
    "Retired",
  ],
  pronouns_options: [
    "She/Her",
    "He/Him",
    "They/Them",
    "Add another pronoun set",
  ],
  transportation_options: ["Bicycle", "Vehicle", "Transit", "Other", "None"],
  gender_options: ["Female", "Male", "Nonbinary", "Add another gender"],
  phone_types: ["DIRECT_DIAL", "WORK", "CELL", "HOME", "OTHER"],
  email_types: ["HOME", "WORK", "OTHER"],
  address_types: ["HOME", "WORK", "OTHER"],
  states: {},
  pairing_factors: [],
  practice_areas: [],
  fieldwork_types: [],
  experiences: [],
  ethnicity: [],
  debrief_templates: [],
  current_term: {},
  previous_term: {},
  next_term: {},
};

const getters = {
  lookup_gender_options: (state) => state.gender_options,
  lookup_phone_types: (state) => state.phone_types,
  lookup_email_types: (state) => state.email_types,
  lookup_address_types: (state) => state.address_types,
  lookup_prefix_options: (state) => state.prefix_options,
  lookup_suffix_options: (state) => state.suffix_options,
  lookup_pronouns_options: (state) => state.pronouns_options,
  lookup_student_status_options: (state) => state.student_statuses,
  lookup_mentor_status_options: (state) => state.mentor_statuses,
  lookup_acad_levels: (state) => state.acad_levels,
  lookup_states: (state) => state.states,
  lookup_pairing_factors: (state) => state.pairing_factors,
  lookup_practice_areas: (state) =>
    state.practice_areas.sort((a, b) =>
      a.description.localeCompare(b.description),
    ),
  lookup_fieldwork_types: (state) => state.fieldwork_types,
  lookup_experiences: (state) => state.experiences,
  lookup_ethnicity: (state) =>
    state.ethnicity.sort((a, b) => a.race.localeCompare(b.race)),
  lookup_debrief_templates: (state) =>
    state.debrief_templates.sort((a, b) => a.name.localeCompare(b.name)),
  lookup_transportation_options: (state) => state.transportation_options,
  lookup_current_term: (state) => state.current_term,
  lookup_previous_term: (state) => state.previous_term,
  lookup_next_term: (state) => state.next_term,
};

const mutations = {
  update_acad_levels(state, acad_levels) {
    state.acad_levels = [];
    if (acad_levels.length > 0) {
      state.acad_levels = acad_levels.sort((a, b) => a.value - b.value);
    }
  },
  update_pairing_factors(state, pairing_factors) {
    state.pairing_factors = [];
    if (pairing_factors.length > 0) {
      state.pairing_factors = pairing_factors
        .map((pf) => {
          const pretty_category_name = pf.category
            .split("_")
            .map(
              (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase(),
            )
            .join(" ");
          pf.description = `${pretty_category_name}: ${pf.name}`;
          return pf;
        })
        .sort((a, b) => a.description.localeCompare(b.description));
    }
  },
  update_states(state, states) {
    state.states = {};
    if (states.length > 0) {
      states.forEach((st) => {
        state.states[st["state_abv"]] = st["state_name"];
      });
    }
  },
  update_practice_areas(state, practice_areas) {
    state.practice_areas = [];
    if (practice_areas.length > 0) {
      state.practice_areas = practice_areas;
    }
  },
  update_fieldwork_types(state, fieldwork_types) {
    state.fieldwork_types = [];
    if (fieldwork_types.length > 0) {
      state.fieldwork_types = fieldwork_types;
    }
  },
  update_experiences(state, experiences) {
    state.experiences = [];
    if (experiences.length > 0) {
      state.experiences = experiences;
    }
  },
  update_ethnicity(state, ethnicity) {
    state.ethnicity = [];
    if (ethnicity.length > 0) {
      state.ethnicity = ethnicity;
    }
  },
  update_debrief_templates(state, templates) {
    state.debrief_templates = [];
    if (templates.length > 0) {
      state.debrief_templates = templates;
    }
  },
  update_current_term(state, term) {
    state.current_term = { ...term };
  },
  update_previous_term(state, term) {
    state.previous_term = { ...term };
  },
  update_next_term(state, term) {
    state.next_term = { ...term };
  },
};

const actions = {
  fetchLookupResource: async ({ dispatch, commit }) => {
    const now = Date.now();
    const updatedAt = localStorage.getItem(`lookups_updated`);
    const lastUpdated = updatedAt
      ? updatedAt
      : now - process.env.VUE_APP_LOOKUP_DATA_RESET_MS - 1;
    if (lastUpdated < now - process.env.VUE_APP_LOOKUP_DATA_RESET_MS) {
      return await dispatch("fetchResource", {
        resource: `lookups`,
        method: "GET",
        params: null,
      }).then((data) => {
        Object.entries(data).forEach((lookup) =>
          commit(`update_${lookup[0]}`, lookup[1]),
        );
        localStorage.setItem("lookups", JSON.stringify(data));
        localStorage.setItem(`lookups_updated`, now);
      });
    } else {
      return await new Promise((resolve, reject) => {
        const ls_lookup_data = localStorage.getItem(`lookups`);
        ls_lookup_data
          ? resolve(JSON.parse(ls_lookup_data))
          : reject(Error(`lookups not defined in localStorage`));
      }).then((data) => {
        Object.entries(data).forEach((lookup) =>
          commit(`update_${lookup[0]}`, lookup[1]),
        );
      });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
