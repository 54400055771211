<template>
  <div class="grid grid-cols-2 md:grid-cols-3 gap-6 md:gap-4 lg:gap-6">
    <div class="box-style col-span-2 md:col-span-3 lg:col-span-2">
      <MyFieldworks height="" :show_all="false" />
    </div>
    <div class="box-style col-span-2 md:col-span-1">
      <YourProgress
        :progress_items="progress_items"
        data-cy="home-YourProgress"
      />
    </div>
    <div
      class="box-style col-span-2 md:col-span-3 lg:col-span-2 md:order-last lg:order-none lg:row-span-2"
    >
      <Milestones @showMilestone="showMilestone" data-cy="home-Milestones" />
      <MyPlan :display_id="display_id" data-cy="home-MyPlan" />
    </div>
    <div
      class="box-style hidden md:block md:col-span-1 md:order-3 lg:order-none lg:row-span-1"
    >
      <MyMentor :mentors="student_mentors" data-cy="content-home-MyMentor" />
    </div>
    <div
      class="box-style hidden md:block md:col-span-1 md:order-2 lg:row-span-1"
    >
      <HereToHelp data-cy="content-home-HereToHelp" />
    </div>
  </div>
</template>

<script>
// Make home component a size that fits in between all bars
// Create a grid that will hold our cards
// Fill grid with various cards (cards will be unique)
// Make sure all cards are spaced evenly
// Name cards based on visual design
import MyFieldworks from "@/components/displays/student-displays/fieldworks-logs/MyFieldworks";
import MyMentor from "@/components/displays/student-displays/mentor/MyMentor";
import YourProgress from "@/components/displays/student-displays/progress/YourProgress";
import HereToHelp from "@/components/displays/student-displays/help/HereToHelp";
import Milestones from "@/components/displays/student-displays/milestones-plan/Milestones";
import MyPlan from "@/components/displays/student-displays/milestones-plan/MyPlan";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "Home",
  components: {
    MyPlan,
    Milestones,
    HereToHelp,
    YourProgress,
    MyMentor,
    MyFieldworks,
  },
  data() {
    return {
      fieldworks: [],
      progress_items: this.$store.state.fieldwork_form_store.progress_items,
      display_id: 11,
    };
  },
  watch: {
    getAcadLevel() {
      this.reload();
    },
  },
  mounted() {
    /*while (this.student.student_loading) {
      console.log("loading");
      window.setTimeout(() => {}, 1000);
    }*/
        this.reload();
  },
  methods: {
    showMilestone: function ({ display_id }) {
      this.set_milestone_step_id(display_id);
      this.display_id = this.display_step;
    },
    reload() {
      if (!this.$store.state.summer) {
        Promise.allSettled([
          this.fetch_milestone_dates(),
          this.fetchStudentMentors(),
          this.fetchPlanResource(),
          this.getFieldworkEntries(),
          this.$store.dispatch("getStudentProgress",this.getAcadLevel.term.term_id)
        ]);
      }
    },
    ...mapActions([
      "fetch_milestone_dates",
      "fetchStudentMentors",
      "getFieldworkEntries",
      "fetchPlanResource",
      "getStudentProgress",
    ]),
    ...mapMutations(["set_milestone_steps_display", "set_milestone_step_id"]),
  },
  computed: {
    ...mapState({
      student: (state) => state.student,
      acad_level: (state) => state.acad_level,
    }),
    ...mapGetters(["student_mentors", "display_step", "getAcadLevel"]),
  },
};
</script>
<style scoped></style>
